.auth-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 35vw;
  margin: 5vh auto 15vh;
  padding: 40px;
  border-radius: 8px;
  border: 1px solid #0706060f;
  box-shadow: 0 4px 24px #00000038;
}

.auth-title {
  margin-bottom: 30px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
}

.auth-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  margin-top: 2px;
  margin-left: 12px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: rgb(185, 178, 178);
}

.question-text {
  display: inline-block;
  margin-right: 7px;
  margin-left: 3px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
}

.auth__btn {
  position: relative;
  min-width: 70px;
  min-height: 38px;
  margin-top: 10px;
  margin-left: 3px;
  border: none;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  background-color: #ff0000;
  color: white;
}

.auth__btn:hover {
  opacity: 0.7;
  color: white;
}

.auth__link {
  margin-top: 20px;
  border-bottom: 1px dashed #6f7072;
  text-decoration: none;
  color: #6f7072;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.auth__link:hover {
  color: #6f7072;
  opacity: 0.7;
}

.auth__input {
  display: block;
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 4px 10px 1px;
  margin-bottom: 15px;
  border: 1px solid #0000001f;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
  background-color: #fff;
  box-shadow: 0 4px 24px #0000000a;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 900px) {
  .auth-container {
    width: 70vw;
  }
}
