.main-content {
  flex: 1 0 auto;
  margin: 30px 100px;
}

.main-preview {
  display: flex;
}

.main-preview__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26vw;
  margin-right: 5vw;
  margin-top: 60px;
}

.main-preview__main-img {
  z-index: 1000;
  position: relative;
  width: 33vw;
  height: 32vh;
  margin-top: 60px;
  border-radius: 5px;
  box-shadow: 0 4px 24px #00000038;
}

.main-preview__side-img {
  z-index: 1;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 4px 24px #00000038;
}

.main-preview__btn {
  height: 50px;
  margin: 0 auto;
  width: 350px;
  border-radius: 8px;
  background: #ff0000;
  color: #fff;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 4px 24px #00000038;
}

.main-preview__btn:hover {
  opacity: 0.7;
  color: #fff;
}

.main-preview__title {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 20px;
  color: #6f7072;
  text-align: center;
}

.main-preview__text {
  padding: 0 30px 85px;
  text-align: justify;
  font-size: 18px;
  color: #6f7072;
}

.main-ads {
  margin-top: 40px;
}

.main-ads__subtitle {
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
}

.main-feedback {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
}

.main-feedback__card {
  display: flex;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 4px 4px 24px #00000038;
}

.main-feedback__text {
  text-align: justify;
  padding: 5px 15px 5px 0;
  width: 28vw;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
}

.main-feedback__contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.main-feedback__link {
  text-decoration: none;
  color: #fff;
}

.main-feedback__link:hover {
  color: #fff;
}

.main-feedback__img {
  height: 27vh;
}

.main-magazine {
  margin: 50px 0 30px;
}

.main-magazine__card-block {
  display: flex;
  justify-content: space-between;
}

.main-magazine__card {
  width: 15vw;
  padding: 25px 11px;
  border-radius: 5px;
  list-style-type: none;
  background-color: #6f7072;
  box-shadow: 3px 10px 24px #01010146;
  opacity: 0.4;
}

.main-magazine__card:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.main-magazine__card-title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.main-magazine__card-text {
  color: #fff;
  text-align: justify;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

@media (min-width: 2200px) and (max-width: 2561px) {
  .main-preview__side-img {
    height: 18vh;
    right: 15vw;
    top: 13vh;
  }

  .main-preview__btn {
    width: 350px;
    font-size: 17px;
  }

  .main-magazine__card {
    width: 14vw;
    height: 17vh;
  }

  .main-magazine__card-title {
    font-size: 20px;
  }

  .main-magazine__card-text {
    color: #fff;
    text-align: justify;
    font-size: 18px;
  }
}

@media (min-width: 1700px) and (max-width: 2199px) {
  .main-preview__main-img {
    height: 28vh;
  }

  .main-preview__side-img {
    height: 18vh;
    right: 11vw;
    top: 15vh;
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  .main-preview__main-img {
    height: 25vh;
  }

  .main-preview__side-img {
    height: 15vh;
    right: 10vw;
    top: 17vh;
  }

  .main-preview__text {
    padding: 0 20px 43px;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .main-preview {
    padding-left: 2rem;
  }

  .main-preview__main-img {
    height: 22vh;
  }

  .main-preview__side-img {
    height: 13vh;
    right: 7vw;
    top: 15vh;
  }

  .main-preview__text {
    padding: 0 0 23px;
  }

  .main-feedback__img {
    height: 16vh;
  }

  .main-magazine__card-block {
    padding-left: 0;
  }
}

@media (min-width: 1020px) and (max-width: 1199px) {
  .main-preview__main-img {
    width: 33vw;
    height: 19vh;
  }

  .main-preview__side-img {
    width: 20vw;
    height: 11vh;
    right: 9vw;
    top: 16vh;
  }

  .main-preview__text {
    padding: 0 0 23px;
    font-size: 15px;
  }

  .main-preview__btn {
    width: 270px;
    font-size: 15px;
    height: 40px;
  }

  .main-feedback__img {
    height: 13vh;
  }

  .main-feedback__text {
    font-size: 15px;
  }

  .main-magazine__card-block {
    padding-left: 0;
  }
}

@media (min-width: 800px) and (max-width: 1019px) {
  .main-preview__centr {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .main-preview__main-img {
    flex-grow: 1;
    margin-top: 20px;
    width: 33vw;
    height: 18vh;
  }

  .main-preview__side-img {
    display: none;
  }

  .main-preview__info {
    margin: 20px 10px;
  }

  .main-preview__text {
    padding: 0 0 13px;
    font-size: 13px;
  }

  .main-preview__btn {
    width: 200px;
    height: 40px;
    font-size: 13px;
  }

  .main-feedback__img {
    display: none;
  }

  .main-feedback__text {
    font-size: 15px;
  }

  .main-magazine__card-block {
    padding-left: 0;
  }

  .main-magazine__card-block {
    justify-content: space-around;
  }

  .main-magazine__card {
    width: 20vw;
  }

  .main-magazine__card:nth-child(2n) {
    display: none;
  }

  .main-feedback {
    justify-content: center;
  }
}

@media (min-width: 600px) and (max-width: 799px) {
  .main-content {
    flex: 1 0 auto;
    margin: 15px 70px;
  }

  .main-preview {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .main-preview__info {
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .main-preview__centr {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .main-preview__main-img {
    flex-grow: 1;
    margin-top: 20px;
    width: 45vw;
    height: 17vh;
  }

  .main-preview__side-img {
    display: none;
  }

  .main-preview__info {
    margin: 20px 10px 20px 0;
  }

  .main-preview__text {
    padding: 0 0 13px;
    font-size: 13px;
  }

  .main-preview__btn {
    width: 200px;
    height: 40px;
    font-size: 13px;
  }

  .main-magazine__card-block {
    padding-left: 0;
  }

  .main-magazine__card-block {
    justify-content: space-around;
  }

  .main-magazine__card {
    width: 20vw;
  }

  .main-magazine__card:nth-child(2n) {
    display: none;
  }

  .main-feedback {
    justify-content: center;
  }

  .main-feedback__img {
    display: none;
  }

  .main-feedback__text {
    font-size: 15px;
  }
}

@media (max-width: 599px) {
  .main-content {
    margin: 15px 30px;
  }

  .main-preview {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .main-preview__info {
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .main-preview__centr {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  .main-preview__main-img {
    margin-top: 20px;
    width: 70vw;
    height: 15vh;
  }

  .main-preview__side-img {
    display: none;
  }

  .main-preview__text {
    padding: 0 0 13px;
    font-size: 13px;
  }

  .main-preview__btn {
    width: 200px;
    height: 40px;
    font-size: 11px;
  }

  .main-magazine__card-block {
    padding-left: 0;
  }

  .main-magazine__card-title {
    font-size: 13px;
  }

  .main-magazine__card-text {
    font-size: 11px;
  }

  .main-magazine__card-block {
    justify-content: space-around;
  }

  .main-magazine__card {
    width: 35vw;
  }

  .main-magazine__card:nth-child(2n + 1) {
    display: none;
  }

  .main-feedback {
    justify-content: center;
  }

  .main-feedback__card {
    flex-direction: column;
  }

  .main-feedback__img {
    display: none;
  }

  .main-feedback__text {
    font-size: 15px;
    width: 100%;
  }
}
