.search-block {
  padding: 20px;
}

.search-input {
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 4px 10px 1px;
  border: 1px solid #0000001f;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
  background-color: #fff;
  box-shadow: 0 4px 24px #0000000a;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-input-w-l {
  flex-grow: 1;
  margin-right: 2vw;
}

.search-input-block {
  display: flex;
  margin-bottom: 10px;
  margin-right: 2vw;
}

.search-input-block:last-child {
  margin-right: 0;
}

.search-input-w-s {
  width: 15vw;
  margin-left: 2px;
  margin-right: 2vw;
  min-height: 40px;
  outline: none;
}

.label-search-input {
  position: absolute;
  z-index: 999;
  margin-top: 2px;
  margin-left: 12px;
  font-size: 12px;
  color: rgb(185, 178, 178);
}

.filter-btn {
  width: 15vw;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-btn {
  background: #ff0000;
  margin-right: 1vw;
}

.clear-btn {
  background: #6f7072;
  opacity: 0.7;
}

.filter-btn:hover {
  opacity: 0.6;
  color: #fff;
}

.search-row {
  display: flex;
  justify-content: space-between;
}

.btn-block {
  width: 20vw;
}

.input-item {
  margin-bottom: 10px;
}

.title-empty {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
  opacity: 0.9;
  text-align: center;
}

@media (max-width: 1000px) {
  .search-row {
    flex-wrap: wrap;
  }

  .search-input-w-s {
    width: 20vw;
  }

  .btn-block {
    width: 65vw;
    margin: 0 auto;
  }

  .filter-btn {
    width: 30vw;
  }
}

@media (min-width: 1700px) {
  .filter-btn {
    width: 100%;
  }

  .search-input-w-s {
    width: 100%;
  }

  .input-item {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .search-row__sub {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  .search-input-w-s {
    width: 70vw;
  }

  .search-row {
    justify-content: center;
  }
}
