.header {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  width: 100%;
  max-height: 90px;
}

.logo {
  height: 35px;
  width: 150px;
  margin-left: 30px;
}

.user-link {
  display: flex;
  position: relative;
  margin-right: 10px;
  padding-top: 18px;
}

.user-link:hover {
  opacity: 0.7;
  color: rgb(117, 115, 115);
}

.user-link_text {
  text-decoration: none;
  color: rgb(117, 115, 115);
}

.btn-toggle {
  border: none;
  padding-top: 20px;
  background-color: transparent;
  opacity: 0.5;
}

button:focus {
  outline: none;
}

.nav-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  background-color: #ff0000;
}

.menu {
  flex-grow: 1;
  display: flex;
  position: relative;
}

#menu__toggle {
  display: none;
}

.menu-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 35px;
  list-style-type: none;
}

.menu-list {
  margin-top: 1px;
}

.menu__item {
  display: block;
  padding: 7px 15px;
  font: 700 14px/18px "Montserrat", sans-serif;
  text-decoration: none;
  transition: opacity 0.5s;
  color: white;
}

.menu__item_active,
.menu__item:hover {
  opacity: 0.7;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

input {
  margin-left: 3px;
  width: 230px;
  outline: none;
  border: none;
  background-color: rgb(243, 235, 235);
}

.input-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 250px;
  margin: 8px 10px;
  padding: 0 5px;
  border: 1px rgb(228, 222, 222) solid;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  outline: none;
  color: rgb(117, 115, 115);
  background-color: rgb(243, 235, 235);
}

.search-dropdown {
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 0;
  left: 0;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px rgb(228, 222, 222) solid;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  transition: 0.4s all;
}

.search-dropdown:active {
  transform: scale(1);
}

.search-dropdown__item {
  padding: 5px 0;
  list-style: none;
  border-bottom: 1px rgb(228, 222, 222) solid;
  color: rgb(117, 115, 115);
}

.search-dropdown__item:hover {
  cursor: pointer;
  opacity: 0.7;
}

a:hover {
  color: rgb(117, 115, 115);
}

.user-block {
  position: relative;
  display: inline-flex;
}

.tooltip-icon {
  display: none;
  position: absolute;
  z-index: 10;
  top: 40px;
  right: 0;
  width: 250px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #6f7072;
}

.tooltip-icon::after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 7%;
  width: 9px;
  height: 9px;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.user-block:hover .tooltip-icon {
  display: block !important;
}

.theme-toggle__block {
  position: relative;
}

.theme-toggle__block:hover .tooltip-icon__button {
  display: block !important;
  top: 43px;
  right: -10px;
}

@media (max-width: 980px) {
  .menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10003;
    top: 8px;
    left: 40px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
  .menu__btn > span::before {
    content: "";
    top: -8px;
  }
  .menu__btn > span::after {
    content: "";
    top: 8px;
  }

  .menu-box {
    display: block;
    position: fixed;
    visibility: hidden;
    z-index: 10002;
    top: 60px;
    left: -100%;
    width: 300px;
    height: 40%;
    margin: 0;
    padding: 30px 0;
    border-radius: 5px;
    text-align: center;
    background-color: #eceff1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  }

  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
  }
  .menu__item:hover {
    background-color: #cfd8dc;
  }

  #menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu-box {
    z-index: 10001;
    visibility: visible;
    left: 0;
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    transition-duration: 0.25s;
  }
  .menu-box {
    transition-duration: 0.25s;
  }
  .menu__item {
    transition-duration: 0.25s;
  }
}

@media (max-width: 400px) {
  .input-search {
    opacity: 0;
  }

  .header {
    padding: 10px 5px;
  }
}
