.footer__wrapper {
  flex: 0 0 auto;
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.footer__logo {
  margin: 10px 0 0 30px;
  height: 35px;
  width: 150px;
}

.footer-contacts {
  display: flex;
  justify-content: flex-end;
  padding-left: 30px;
}

.footer-contacts__links {
  margin-right: 10px;
  border-radius: 5px;
  padding: 2px 0 2px 6px;
  width: 28px;
  text-decoration: none;
  background-color: rgb(185, 178, 178);
  color: rgba(0, 0, 0, 0.692);
  opacity: 0.7;
}

.footer-contacts__links:hover {
  color: white;
  opacity: 0.3;
}

.footer__text {
  margin: 10px 30px;
  width: 250px;
  color: rgb(185, 178, 178);
  font-family: "Montserrat", sans-serif;
  opacity: 0.7;
}

.footer__text_links {
  padding-left: 7px;
  color: rgb(185, 178, 178);
}

.footer__text_links:hover {
  color: white;
  opacity: 0.7;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: black;
}

.footer-bottom__author {
  max-height: 50px;
  margin: 0 auto;
  color: rgb(185, 178, 178);
  opacity: 0.3;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 750px) {
  .footer {
    flex-direction: column;
  }

  .footer__text {
    width: 100%;
    margin: 10px;
    font-size: 14px;
  }

  .footer__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .footer-contacts {
    padding-left: 0;
  }
}
